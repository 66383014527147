import React, { useState, useRef, useEffect } from 'react';
import '../HomePage.css';
import Header from './Header';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoRef = useRef(null);
  const modalVideoRef = useRef(null);

  // AOS animasyonlarını başlat
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  // Videoyu hem web hem de mobil/tablet cihazlarda otomatik oynat
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true; // Otomatik oynatma için video sessize alınmalı
      videoRef.current.play().catch((error) => {
        console.error('Video otomatik oynatma hatası:', error);
      });
    }
  }, []);

  const handleVideoClick = () => {
    setIsModalOpen(true);
    setTimeout(() => {
      if (modalVideoRef.current) {
        modalVideoRef.current.play(); // Modal içindeki videoyu oynat
      }
    }, 300); // Modalın render edilmesi için bekleme süresi
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (modalVideoRef.current) {
      modalVideoRef.current.pause(); // Modal kapatıldığında videoyu duraklat
      modalVideoRef.current.currentTime = 0; // Videoyu başlangıca al
    }
  };

  return (
    <div>
      <Header />

      <main>
        {/* Hero Section */}
        <section
          className="container mx-auto px-4 md:px-8 lg:px-12 mt-0 md:mt-0 lg:mt-0"
          data-aos="fade-up"
        >
          <div className="row flex flex-col md:flex-row pt-24 md:pt-0">
            <div className="col-12 md:order-2 push-bottom" data-aos="fade-up">
              <h1 className="text-3xl md:text-4xl lg:text-6xl font-clash font-bold spac mb-4 tracking-tight">
                Stories in Motion
              </h1>
              <div className="rich-text lead" data-aos="fade-up">
                <p className="text-base md:text-lg lg:text-2xl font-apfel">
                  I believe in the power of storytelling and the magic of sharing each unique journey.
                  As a videographer and social media marketing expert, my mission is to bring your story to life and make it stand out.
                  <br />
                  <br />
                  Drawing from my diverse experiences and armed with some cool gadgets, I create visual content and videos that grab attention and engage.
                  My expertise in social media marketing strategies ensures your story reaches the right audience and makes a boom.
                  Want to transform your story into a standout piece that gets everyone talking?
                  Let’s create visuals and implement social media marketing strategies that connect, engage, and leave a powerful impression across all platforms!
                </p>
              </div>
            </div>

            {/* Showreel */}
            <div className="col-12 md:order-1 push-bottom" data-aos="fade-up">
              <div className="showreel cursor-pointer relative" onClick={handleVideoClick} data-aos="fade-up">
                <img
                  src="/images/Showreel-kapak.jpg"
                  alt="Showreel Preview"
                  className="showreel__video w-full h-auto"
                />

              </div>
            </div>
          </div>
        </section>
        {/* Hero Section End */}

        {/* Portrait Section */}
        <section className="container portrait push-bottom mx-auto px-4 md:px-8 lg:px-12" data-aos="fade-up">
          <div className="row push-bottom-s">
            {/* Sol sütun - resim */}
            <div className="col-6 col-md-5 offset-md-1 col-lg-4 offset-lg-0 col-xl-5 col-xxl-5 offset-xxl-1" data-aos="fade-up">
              <picture>
                <img
                  src="/images/Profile.jpeg"
                  alt="Oguzcan Turan"
                  className="w-full h-auto"
                  data-aos="fade-up"
                  loading="lazy"
                />
              </picture>
            </div>

            {/* Sağ sütun - metin */}
            <div className="col-6 col-lg-8 col-xl-7 col-xxl-6" data-aos="fade-up">
              <h2 className="portrait__title text-3xl md:text-4xl lg:text-5xl font-clash font-bold mb-4">
                Oguzcan
                <br />
                Turan
              </h2>

              <div className="rich-text push-bottom-s" data-aos="fade-up">
                <p className="text-base md:text-lg lg:text-xl font-apfel w-full md:w-full lg:w-auto">
                  From the vibrant streets of Istanbul to the heart of Dubai, my journey in storytelling has spanned over a decade.
                  Growing up exploring Istanbul, I’ve immersed myself in the magic of storytelling.
                  My journey has led me to create compelling videos and impactful campaigns for a diverse range of brands.
                  My professional experience spans several film production companies and advertising agencies,
                  allowing me to work with globally recognized brands like Nutella, Huawei, Al Jazeera, and Turkish Airlines.
                  As both a videographer and social media marketer, I blend my creative vision with the latest technology to craft visuals that captivate,
                  engage, and leave a lasting mark. Now that I’m based in Dubai, I’m thrilled to be creating and sharing stories here,
                  capturing the unique energy of this dynamic city and bringing new narratives to life for and from Dubai.
                </p>
              </div>
            </div>
          </div>

          {/* Full-width Services Section */}
          <div className="w-full mt-12" data-aos="fade-up">
            <h1 className="font-bold font-clash text-3xl mb-4">Services</h1>

            <p className="text-base md:text-lg lg:text-xl font-apfel">
              <strong className="font-extrabold text-2xl">Videography:</strong>
              I offer a wide range of videography services, from concept development and scripting to final editing.
              My expertise includes creating promotional videos, social media content, campaign and commercial films, interviews, brand stories,
              concerts, event coverage, and architecture videography. I focus on producing high-quality videos that visually captivate,
              convey your message effectively, and resonate with your audience.
            </p>

            <p className="text-base md:text-lg lg:text-xl font-apfel mt-6">
              <strong className="font-extrabold text-2xl">Social Media Marketing:</strong>
              I craft and execute tailored social media marketing strategies to elevate your brand’s presence.
              My services include creative content writing, campaign creation, full social media management, target audience management,
              content pillar development, and influencer marketing.
              From developing engaging content and running impactful campaigns to managing interactions and collaborating with influencers,
              I ensure your brand connects with the right audience and makes a powerful impact.
              Ready to transform your story into compelling visuals and effective strategies? Let’s collaborate to create videos and social media solutions
              that connect, engage, and stand out.
            </p>
          </div>
        </section>
        {/* Portrait Section End */}

        {/* Video Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center min-h-screen" onClick={handleCloseModal}>
            <div className="relative w-full max-w-3xl p-4 md:p-8 bg-gray-900 rounded-lg shadow-2xl" onClick={(e) => e.stopPropagation()}>
              {/* Video yükleniyorken spinner gösterimi */}
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-12 h-12 border-4 border-t-transparent border-gray-300 rounded-full animate-spin"></div>
              </div>

              <div
                className="modal-video w-full h-auto rounded-lg z-10 relative"
                dangerouslySetInnerHTML={{
                  __html: `<iframe width="960" height="515" src="https://www.youtube.com/embed/BLJOXmGTlas?si=1hk2xr2sTqZKjbQT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
                }}
              />


              {/* Kapatma Butonu */}
              <button
                className="absolute top-4 right-4 md:top-6 md:right-6 hover:bg-opacity-100 text-4xl text-white p-3 transition-all duration-300 ease-in-out shadow-xl z-30 focus:outline-none focus:ring-4 focus:ring-white"
                onClick={handleCloseModal}
                aria-label="Close video"
              >
                &times;
              </button>
            </div>
          </div>
        )}
        {/* Video Modal End */}
      </main>

      {/* Contact Section */}
      <div className="bg-grey py-8" data-aos="fade-up">
        <div className="container mx-auto px-4 md:px-8 lg:px-12 text-center" data-aos="fade-up">
          <h2 className="text-xl md:text-2xl lg:text-4xl mb-4 font-clash font-bold tracking-tighter">
            Great conversations lead to great projects.
            <br />
            It all starts with a great conversation.
          </h2>
          <a className="a text-lg md:text-xl lg:text-2xl font-apfel" href="mailto:oguzcanturan93@gmail.com" data-aos="fade-up">
            info@oguzcanturan.com
          </a>
        </div>
      </div>
      {/* Contact Section End */}
    </div>
  );
};

export default HomePage;
